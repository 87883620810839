/**
 * Types of JavaScript events that we send to and receive from the
 * Google iframe page.
 *
 * Please synchronize with `GoogleMessageType` in
 * metaserver/static/js/typings/external/google_dss.d.ts.
 *
 * Note: these are google-specific. The generic Cloud Docs message protocol action names
 * and parameters are defined in metaserver/static/js/modules/clean/cloud_docs/types.
 */
export const enum GoogleMessageValues {
  AclFixerClosed = 'AclFixerClosed',
  AclFixerOpened = 'AclFixerOpened',
  ClientFragmentsChanged = 'ClientFragmentsChanged',
  ClientReady = 'ClientReady',
  HostFragmentsChanged = 'HostFragmentsChanged',
  HostReady = 'HostReady',
  MetadataChanged = 'MetadataChanged',
  ReloadHost = 'ReloadHost',
  SharingOpened = 'SharingOpened',
  TitleChanged = 'TitleChanged',
}

export const enum GoogleMessageToHost {
  AclFixerOpened = 'AclFixerOpened',
  ClientFragmentsChanged = 'ClientFragmentsChanged',
  ClientReady = 'ClientReady',
  SharingOpened = 'SharingOpened',
  TitleChanged = 'TitleChanged',
  ReloadHost = 'ReloadHost',
}

export const enum GoogleMessageToEditor {
  AclFixerClosed = 'AclFixerClosed',
  HostFragmentsChanged = 'HostFragmentsChanged',
  HostReady = 'HostReady',
  MetadataChanged = 'MetadataChanged',
}

import {GoogleClientForm, GoogleIframeObject} from 'external/google_dss_objects';

/**
 * Google fsip module.
 */
export interface Fsip {
  /**
   * Creates a Google DSS iframe based on the supplied parameters.
   */
  createIframe: (
    parentElement: Element,
    clientForm: GoogleClientForm,
    attributes: {[key: string]: string}
  ) => GoogleIframeObject;
}

export interface GApi {
  /**
   * Loads a Google API module.
   */
  load: (moduleName: string, callback: () => void) => void;

  /**
   * Google fsip module.
   */
  fsip: Fsip;
}

export declare const gapi: GApi;
