import {
  ActionSource,
  ActionSourceWeb,
  ActionSourceWebBrowse,
  ActionSourceWebHome,
  ActionSourceWebPreview,
  ActionSourceWebSearch,
  Client,
  CloudDocsEditorGoogleDocs,
  CloudDocsEditorGoogleSheets,
  CloudDocsEditorGoogleSlides,
  CloudDocsEditorWordOnline,
  CloudDocsEditorExcelOnline,
  CloudDocsEditorPowerpointOnline,
  CloudDocsEditorPaperWeb,
  EventNameGddRegisterClientCalled,
  EventNameLoadIframeAttempt,
  EventNameLoadIframeSuccess,
  EventNameLoadIframeFailed,
  EventNameLoadingScreenEnd,
  EventNameLoadingScreenTimeout,
  EventNameReceivedMessageAclFixerClosed,
  EventNameReceivedMessageAclFixerOpened,
  EventNameReceivedMessageClientFragmentsChanged,
  EventNameReceivedMessageClientReady,
  EventNameSentMessageHostFragmentsChanged,
  EventNameSentMessageHostReady,
  EventNameSentMessageMetadataChanged,
  EventNameReceivedMessageSharingOpened,
  EventNameReceivedMessageTitleChanged,
  EventNameReceivedMessageReloadHost,
  EventNameModalRestoreAttempt,
  EventNameModalRestoreSuccess,
  EventNameModalRestoreFailed,
  EventNameModalBackHome,
  EventNameShowedModalFileLocked,
  EventNameShowedModalNearQuota,
  EventNameShowedModalOverQuota,
  EventNameShowedModalPermissionUpgraded,
  EventNameShowedModalPermissionDowngraded,
  EventNameShowedModalPermissionDenied,
  EventNameShowedModalDeletedCanRestore,
  EventNameShowedModalDeletedCantRestore,
  EventNameShowedModalPermanentlyDeleted,
  EventNameShowedModalUnauthenticated,
  EventNameShowedModalUserNotLoggedIn,
  EventNameMaxAccessUrlChanged,
  EventName,
  IntegrationSetting,
  UserActionCreate,
  UserActionOpen,
  UserActionPreOpen,
  UserActionPreview,
  UserActionLoadingSnackBarImpression,
  UserActionLoadingSnackBarOpenPreview,
} from 'modules/clean/api_v2/datatypes/cloud_docs';
import {CloudDocTypeSimplePointer} from 'modules/clean/api_v2/datatypes/files';
import {BrowserHistoryUpdateAction} from 'modules/clean/cloud_docs/cloud_doc_iframe_helpers';
import {Message} from 'modules/clean/frame_messenger/frame_messenger_base';
import {ReactFormatResult} from 'modules/clean/react_format';

// This module defines Cloud Docs types shared in the codebase.
//
// The message protocol between the Dropbox parent window and the Provider iframe is defined in
//   https://paper.dropbox.com/doc/Cloud-Docs-inter-frame-messages-rVopAaY4cNVie44EEMZIK.
//
// In these types the "Action" refers to the string name of the action and "Message"
// refers to the JSON message object that contains the `action` property as well as additional
// properties to control that action
//
// NOTE: Keep these definitions in sync with those copied to the Paper codebase until we have
// proper code sharing. See: paper/etherpad/src/client/cloud_docs/cloud_docs_message_types.tsx.

/**
 * Action names for messages that are sent from the Provider iframe to Dropbox's parent window.
 */
// tslint:disable-next-line:prefer-const-enum Keep the lookup table so we can call _.values.
export enum CloudDocActionsToDropbox {
  ChildReady = 'cloud-docs-child-frame-ready',
  ChildViewable = 'cloud-docs-child-frame-viewable',
  ChildInteractive = 'cloud-docs-child-frame-interactive',
  OpenAddAccessUI = 'cloud-docs-open-add-access-ui',
  NavigateNewFragment = 'cloud-docs-navigate-new-fragment',
  OpenSharingUI = 'cloud-docs-open-sharing-ui',
  TitleChanged = 'cloud-docs-title-changed',
  ReloadHost = 'cloud-docs-reload-host',
  ProviderFrameCalled = 'cloud-docs-provider-frame-called',
}

export const enum GDDSpecificActionsToDropbox {
  LogEvent = 'gdd-log-event',
  Ready = 'gdd-ready',
  FrameLoaded = 'gdd-frame-loaded',
}

export const enum PaperSpecificActionsToDropbox {
  GetToken = 'cloud-docs-get-token',
  NavigateNewDoc = 'cloud-docs-navigate-new-doc',
  NavigateCompleted = 'cloud-docs-navigate-completed',
  OpenSearchUi = 'open-search-ui',
  OpenDocMentionUI = 'open-doc-mention-ui',
  MountDropboxNotificationsDropdown = 'cloud-docs-mount-dropbox-notifications-dropdown',
}

/**
 * Action names for messages that are sent from the Dropbox host to the GDD intermediate iframe.
 */
export const enum CloudDocActionsToIntermediateFrame {
  Init = 'intermediate-frame-init',
}

/**
 * Action names for messages that are sent from Dropbox's parent window to the Provider's iframe.
 */
// tslint:disable-next-line:prefer-const-enum Keep the lookup table so we can call _.values.
export enum CloudDocActionsToProvider {
  HostReady = 'cloud-docs-host-ready',
  AddAccessUIResponse = 'cloud-docs-add-access-response',
  NavigateNewFragment = 'cloud-docs-navigate-new-fragment',
  NavigateNewHistory = 'cloud-docs-navigate-new-history',
  MetadataChanged = 'cloud-docs-metadata-changed',
  // !!This should match the message sent in frame_messenger_host as part of registration.
  FrameMessengerParentReady = 'parent-ready',
}

// tslint:disable-next-line:prefer-const-enum Keep the lookup table so we can call _.values.
export enum PaperSpecificActionsToProvider {
  NavigateNewDoc = 'cloud-docs-navigate-new-doc',
  GrantToken = 'cloud-docs-token',
  SearchUiClosed = 'search-ui-closed',
  DocMentionUIClosed = 'doc-mention-ui-closed',
  MemberCountChanged = 'cloud-docs-member-count-changed',
}

export class ChildReadyMessage implements Message {
  action: CloudDocActionsToDropbox.ChildReady;
  parameters: {
    sentTimestampMs?: number;
  };
}

export class HostReadyMessage implements Message {
  action: CloudDocActionsToProvider.HostReady;
}

export class ChildViewableMessage implements Message {
  action: CloudDocActionsToDropbox.ChildViewable;
}

export class ChildInteractiveMessage implements Message {
  action: CloudDocActionsToDropbox.ChildInteractive;
}

export class IntermediateFrameInitMessage implements Message {
  action: CloudDocActionsToIntermediateFrame.Init;
  parameters: {
    action_url: string;
    auth_token: string;
    auth_token_ttl: number;
    user_email: string;
    doc_id: string;
    mock_iframe_url: string;
  };
}

export class OpenAddAccessUIMessage implements Message {
  action: CloudDocActionsToDropbox.OpenAddAccessUI;
  parameters: {
    cloudDocId: string;
    docId?: string;
    emailAddresses: string[];
    // TBD
  };
}

class NavigateHistoryMessage {
  parameters: {
    cloudDocId: string;
    docId?: string;
    token: string;
    queryParams: {[key: string]: string};
  };
}

export class NavigateNewHistoryMessageToProvider extends NavigateHistoryMessage implements Message {
  action: CloudDocActionsToProvider.NavigateNewHistory;
}

export class NavigateNewFragmentMessageToProvider extends NavigateHistoryMessage
  implements Message {
  action: CloudDocActionsToProvider.NavigateNewFragment;
}

/**
 * This message allows sending a new fragment to the host frame for updating the url.
 * Optionally, query parameters can be included in addition to the fragment or in lieu of.
 * An optional parameter browserHistoryUpdateAction dictates what browser history
 * action will be taken (i.e. pushState, replaceState, none).
 */
export class NavigateNewFragmentMessageToDropbox implements Message {
  action: CloudDocActionsToDropbox.NavigateNewFragment;
  parameters: {
    cloudDocId: string;
    docId?: string;
    token?: string;
    queryParams?: {[key: string]: string | null};
    browserHistoryUpdateAction?: BrowserHistoryUpdateAction;
  };
}

export class ReloadHostMessage implements Message {
  action: CloudDocActionsToDropbox.ReloadHost;
  parameters: {
    cloudDocId: string;
    docId?: string;
  };
}

export class TitleChangedMessage implements Message {
  action: CloudDocActionsToDropbox.TitleChanged;
  parameters: {
    cloudDocId: string;
    docId?: string;
    title: string;
    faviconUrl?: string;
  };
}

export class OpenSharingUIMessage implements Message {
  action: CloudDocActionsToDropbox.OpenSharingUI;
  parameters: {
    cloudDocId: string;
    docId?: string;
    emailAddresses?: string[];
    // TBD
  };
}

export class AddAccessUIResponseMessage implements Message {
  action: CloudDocActionsToProvider.AddAccessUIResponse;
  parameters: {
    cloudDocId: string;
    docId: string;
  };
}

export class MetadataChangedMessage implements Message {
  action: CloudDocActionsToProvider.MetadataChanged;
  parameters: {
    cloudDocId: string;
    docId: string;
  };
}

export class GddLogEventMessage implements Message {
  action: GDDSpecificActionsToDropbox.LogEvent;
  parameters: {
    eventName: EventName;
    docId: string;
  };
}

export class ProviderFrameCalledMessage implements Message {
  action: CloudDocActionsToDropbox.ProviderFrameCalled;
  parameters: {
    frameCalledTs: number;
  };
}

export class GetTokenMessage implements Message {
  action: PaperSpecificActionsToDropbox.GetToken;
  parameters: {
    cloudDocId: string;
    docId?: string;
  };
}

export class GrantTokenMessage implements Message {
  action: PaperSpecificActionsToProvider.GrantToken;
  parameters: {
    cloudDocId: string;
    docId: string;
    authToken?: string;
    authTokenExpiry?: number;
    errorReason?: Error;
  };
}

export class NavigateNewDocMessage implements Message {
  action: PaperSpecificActionsToDropbox.NavigateNewDoc;
  parameters: {
    cloudDocId: string; // the id of the cloud doc we are navigating to
    docId?: string;
  };
}

export class NavigateCompletedMessage implements Message {
  action: PaperSpecificActionsToDropbox.NavigateCompleted;
  parameters: {
    cloudDocId: string; // the id of the cloud doc for which navigation is complete
    docId?: string;
  };
}

export class MountDropboxNotificationsDropdownMessage implements Message {
  action: PaperSpecificActionsToDropbox.MountDropboxNotificationsDropdown;
  parameters: {
    top: number;
    right: number;
    visible: boolean;
  };
}

export class GddReadyMessage implements Message {
  action: GDDSpecificActionsToDropbox.Ready;
}

export class GddFrameLoadedMessage implements Message {
  action: GDDSpecificActionsToDropbox.FrameLoaded;
}

export const enum SearchResultClickAction {
  Navigate = 'navigate',
  SendInfo = 'send-info',
}

export class OpenSearchUiMessage implements Message {
  action: PaperSpecificActionsToDropbox.OpenSearchUi;
  parameters: {
    cloudDocId: string;
    docId?: string;
    top: number;
    right: number;
  };
}

export class SearchUiClosedMessage implements Message {
  action: PaperSpecificActionsToProvider.SearchUiClosed;
  parameters: {
    cloudDocId: string;
    docId: string;
  };
}

export class OpenDocMentionUIMessage implements Message {
  action: PaperSpecificActionsToDropbox.OpenDocMentionUI;
  parameters: {
    cloudDocId: string;
    docId?: string;
    top: number;
    left: number;
  };
}

export const enum SearchUIResultType {
  CloudDoc = 'cloud-doc',
  File = 'file',
  Folder = 'folder',
  Paper = 'paper',
  PaperFolder = 'paper-folder',
}

export interface DocMentionUISelection {
  title: string;
  docType: SearchUIResultType;
  isPaper: boolean;
  url: string | null;
  fqPath: string | null;
  fileId: string | null;
}

export class DocMentionUIClosedMessage implements Message {
  action: PaperSpecificActionsToProvider.DocMentionUIClosed;
  parameters: {
    cloudDocId: string;
    docId: string;
    selection: DocMentionUISelection | null;
  };
}

export class MemberCountChanged implements Message {
  action: PaperSpecificActionsToProvider.MemberCountChanged;
  parameters: {
    cloudDocId: string;
    docId: string;
  };
}

/**
 * Special case message for the registration of the frame messenger that is used
 * to communicate between the child and parent iframes.
 */
export class FrameMessengerParentReady implements Message {
  action: CloudDocActionsToProvider.FrameMessengerParentReady;
}

export type CloudDocMessageToDropbox =
  | ChildReadyMessage
  | ChildViewableMessage
  | ChildInteractiveMessage
  | OpenAddAccessUIMessage
  | NavigateNewFragmentMessageToDropbox
  | OpenSharingUIMessage
  | TitleChangedMessage
  | ReloadHostMessage
  | GetTokenMessage
  | ProviderFrameCalledMessage
  | GDDSpecificMessageToDropbox;

export type MessagePayloadsToProvider =
  | HostReadyMessage
  | AddAccessUIResponseMessage
  | NavigateNewFragmentMessageToProvider
  | NavigateNewHistoryMessageToProvider
  | MetadataChangedMessage
  | GrantTokenMessage
  | FrameMessengerParentReady;

export type GDDSpecificMessageToDropbox =
  | GddLogEventMessage
  | GddReadyMessage
  | ChildReadyMessage
  | GddFrameLoadedMessage
  | ProviderFrameCalledMessage;

export type PaperSpecificMessageToDropbox =
  | GetTokenMessage
  | OpenSearchUiMessage
  | NavigateNewDocMessage
  | NavigateCompletedMessage
  | OpenDocMentionUIMessage
  | MountDropboxNotificationsDropdownMessage;

export type ProviderSpecificActionsToDropbox =
  | PaperSpecificActionsToDropbox
  | GDDSpecificActionsToDropbox;

export type ProviderSpecificMessageToDropbox =
  | GDDSpecificMessageToDropbox
  | PaperSpecificMessageToDropbox;

/**
 * Data for initializing Bolt client.
 */
export interface BoltData {
  uniqueId: string;
  revision: string;
  token: string;
}

/**
 * TPAT token authentication data.
 */
export interface TpatData {
  tpat: string;
  tpatExpirationTime: number;
}

/**
 * Data for populating the Share modal
 * Keep this in sync with: metaserver/lib/cloud_docs/constants.py::ShareModalMode
 */
export interface ShareModalMode {
  emails?: string;
  variant?: ShareModalVariant;
}

/**
 * The Share Modal can be viewed in three modes: unfilled, prefilled with email addresses or open link settings
 * Keep this in sync with: metaserver/lib/cloud_docs/constants.py::ShareModalVariant
 */
export const enum ShareModalVariant {
  closed = 'closed',
  prefilled = 'prefilled',
  unfilled = 'unfilled',
  linkSettings = 'linksettings',
}

/**
 * TPAT token authentication data.
 */
export interface UserData {
  id: number;
  email: string;
  organizationType: string;
}

/**
 * Cloud Docs types to be used with the `2/cloud_docs/create` endpoint.
 */
export const SimplePointer: CloudDocTypeSimplePointer = {
  '.tag': 'simple_pointer',
};

/**
 * Possible events Cloud Docs clients can log to our `2/cloud_docs/log_client_event` endpoint.
 */

export const GddRegisterClientCalled: EventNameGddRegisterClientCalled = {
  '.tag': 'gdd_register_client_called',
};
export const LoadIframeAttempt: EventNameLoadIframeAttempt = {'.tag': 'load_iframe_attempt'};
export const LoadIframeSuccess: EventNameLoadIframeSuccess = {'.tag': 'load_iframe_success'};
export const LoadIframeFailed: EventNameLoadIframeFailed = {'.tag': 'load_iframe_failed'};
export const LoadingScreenEnd: EventNameLoadingScreenEnd = {'.tag': 'loading_screen_end'};
export const LoadingScreenTimeout: EventNameLoadingScreenTimeout = {
  '.tag': 'loading_screen_timeout',
};
export const ReceivedMessageAclFixerClosed: EventNameReceivedMessageAclFixerClosed = {
  '.tag': 'received_message_acl_fixer_closed',
};
export const ReceivedMessageAclFixerOpened: EventNameReceivedMessageAclFixerOpened = {
  '.tag': 'received_message_acl_fixer_opened',
};
export const ReceivedMessageClientFragmentsChanged: EventNameReceivedMessageClientFragmentsChanged = {
  '.tag': 'received_message_client_fragments_changed',
};
export const ReceivedMessageClientReady: EventNameReceivedMessageClientReady = {
  '.tag': 'received_message_client_ready',
};
export const SentMessageHostFragmentsChanged: EventNameSentMessageHostFragmentsChanged = {
  '.tag': 'sent_message_host_fragments_changed',
};
export const SentMessageHostReady: EventNameSentMessageHostReady = {
  '.tag': 'sent_message_host_ready',
};
export const SentMessageMetadataChanged: EventNameSentMessageMetadataChanged = {
  '.tag': 'sent_message_metadata_changed',
};
export const ReceivedMessageSharingOpened: EventNameReceivedMessageSharingOpened = {
  '.tag': 'received_message_sharing_opened',
};
export const ReceivedMessageTitleChanged: EventNameReceivedMessageTitleChanged = {
  '.tag': 'received_message_title_changed',
};
export const ReceivedMessageReloadHost: EventNameReceivedMessageReloadHost = {
  '.tag': 'received_message_reload_host',
};
export const RestoreAttempt: EventNameModalRestoreAttempt = {'.tag': 'modal_restore_attempt'};
export const RestoreSuccess: EventNameModalRestoreSuccess = {'.tag': 'modal_restore_success'};
export const RestoreFailed: EventNameModalRestoreFailed = {'.tag': 'modal_restore_failed'};
export const BackHome: EventNameModalBackHome = {'.tag': 'modal_back_home'};
export const PermissionUpgraded: EventNameShowedModalPermissionUpgraded = {
  '.tag': 'showed_modal_permission_upgraded',
};
export const PermissionDowngraded: EventNameShowedModalPermissionDowngraded = {
  '.tag': 'showed_modal_permission_downgraded',
};
export const Unauthenticated: EventNameShowedModalUnauthenticated = {
  '.tag': 'showed_modal_unauthenticated',
};
export const PermissionDenied: EventNameShowedModalPermissionDenied = {
  '.tag': 'showed_modal_permission_denied',
};
export const DeletedCanRestore: EventNameShowedModalDeletedCanRestore = {
  '.tag': 'showed_modal_deleted_can_restore',
};
export const DeletedCantRestore: EventNameShowedModalDeletedCantRestore = {
  '.tag': 'showed_modal_deleted_cant_restore',
};
export const PermanentlyDeleted: EventNameShowedModalPermanentlyDeleted = {
  '.tag': 'showed_modal_permanently_deleted',
};
export const UserNotLoggedIn: EventNameShowedModalUserNotLoggedIn = {
  '.tag': 'showed_modal_user_not_logged_in',
};
export const NearQuota: EventNameShowedModalNearQuota = {
  '.tag': 'showed_modal_near_quota',
};
export const OverQuota: EventNameShowedModalOverQuota = {
  '.tag': 'showed_modal_over_quota',
};
export const FileLocked: EventNameShowedModalFileLocked = {
  '.tag': 'showed_modal_file_locked',
};
export const MaxAccessUrlChanged: EventNameMaxAccessUrlChanged = {
  '.tag': 'max_access_url_changed',
};

/**
 * Mapping of message actions received from the child iframe to the corresponding
 * `2/cloud_docs/log_client_event` for that message.
 *
 * If a message action maps to a non-null event, the ProviderIframeContainer
 * layer will log the corresponding event.
 *
 * TODO(pifs, cloud_docs): Figure out plan for how GDDSpecificActionsToDropbox's and
 * PaperSpecificActionsToDropbox's should map to event in `cloud_docs_client_events`.
 * and update mapping.
 */
export const actionReceivedToEvent: {
  [actions in
    | CloudDocActionsToDropbox
    | GDDSpecificActionsToDropbox
    | PaperSpecificActionsToDropbox]: EventName | null;
} = {
  // Generic message actions
  [CloudDocActionsToDropbox.ChildReady]: ReceivedMessageClientReady,
  [CloudDocActionsToDropbox.ChildViewable]: null,
  [CloudDocActionsToDropbox.ChildInteractive]: null,
  [CloudDocActionsToDropbox.OpenAddAccessUI]: ReceivedMessageAclFixerOpened,
  [CloudDocActionsToDropbox.NavigateNewFragment]: ReceivedMessageClientFragmentsChanged,
  [CloudDocActionsToDropbox.OpenSharingUI]: ReceivedMessageSharingOpened,
  [CloudDocActionsToDropbox.TitleChanged]: ReceivedMessageTitleChanged,
  [CloudDocActionsToDropbox.ReloadHost]: ReceivedMessageReloadHost,
  [CloudDocActionsToDropbox.ProviderFrameCalled]: null,

  // GDD-specific message actions
  [GDDSpecificActionsToDropbox.LogEvent]: null,
  [GDDSpecificActionsToDropbox.Ready]: null,
  [GDDSpecificActionsToDropbox.FrameLoaded]: null,

  // Paper-specific message actions
  [PaperSpecificActionsToDropbox.GetToken]: null,
  [PaperSpecificActionsToDropbox.NavigateNewDoc]: null,
  [PaperSpecificActionsToDropbox.NavigateCompleted]: null,
  [PaperSpecificActionsToDropbox.OpenSearchUi]: null,
  [PaperSpecificActionsToDropbox.OpenDocMentionUI]: null,
  [PaperSpecificActionsToDropbox.MountDropboxNotificationsDropdown]: null,
};

/**
 * Possible clients that can log to our `2/cloud_docs/log_client_event` endpoint.
 */
export const docTypeToClient: {[index: string]: Client} = {
  paper: {'.tag': 'dropbox_paper'},
  gdoc: {'.tag': 'gdd_docs'},
  gsheet: {'.tag': 'gdd_sheets'},
  gslides: {'.tag': 'gdd_slides'},
};

export type CloudDocPlatform = 'web' | 'desktop' | 'mobile' | 'unknown' | 'other';

// TODO (CDOCSX-681): This should probably be rolled in to a nice Integration class
// that includes the integration name and its mapping to a sharing action.
// If you add an integration here you'll need to be sure to update the
// cloud_docs.utils.integrationNameToShareActionOrigin() to get the tests to pass.
export enum IntegrationName {
  GOOGLE_DSS = 'GOOGLE_DSS',
  PAPER_INTEGRATION = 'PAPER_INTEGRATION',
  OFFICE_ONLINE = 'OFFICE_ONLINE',
}

/**
 * User Action Logging
 */
export const CreateAction: UserActionCreate = {'.tag': 'create'};
export const OpenAction: UserActionOpen = {'.tag': 'open'};
export const PreOpenAction: UserActionPreOpen = {'.tag': 'pre_open'};
export const PreviewAction: UserActionPreview = {'.tag': 'preview'};
export const LoadingSnackbarImpression: UserActionLoadingSnackBarImpression = {
  '.tag': 'loading_snack_bar_impression',
};
export const LoadingSnackbarOpenPreview: UserActionLoadingSnackBarOpenPreview = {
  '.tag': 'loading_snack_bar_open_preview',
};
export const UserActionEventType = {
  CREATE: CreateAction,
  OPEN: OpenAction,
  PRE_OPEN: PreOpenAction,
  PREVIEW: PreviewAction,
  LOADING_SNACKBAR_IMPRESSION: LoadingSnackbarImpression,
  LOADING_SNACKBAR_OPEN_PREVIEW: LoadingSnackbarOpenPreview,
};

export const WebBrowseSource: ActionSourceWebBrowse = {'.tag': 'web_browse'};
export const WebHomeSource: ActionSourceWebHome = {'.tag': 'web_home'};
export const WebSearchSource: ActionSourceWebSearch = {'.tag': 'web_search'};
export const WebPreviewSource: ActionSourceWebPreview = {'.tag': 'web_preview'};
export const WebSource: ActionSourceWeb = {'.tag': 'web'};
export const UserActionSourceType = {
  WEB: WebSource,
  WEB_BROWSE: WebBrowseSource,
  WEB_HOME: WebHomeSource,
  WEB_SEARCH: WebSearchSource,
  WEB_PREVIEW: WebPreviewSource,
};

export const GoogleDocsEditor: CloudDocsEditorGoogleDocs = {'.tag': 'google_docs'};
export const GoogleSheetsEditor: CloudDocsEditorGoogleSheets = {'.tag': 'google_sheets'};
export const GoogleSlidesEditor: CloudDocsEditorGoogleSlides = {'.tag': 'google_slides'};
export const WordOnlineEditor: CloudDocsEditorWordOnline = {'.tag': 'word_online'};
export const ExcelOnlineEditor: CloudDocsEditorExcelOnline = {'.tag': 'excel_online'};
export const PowerpointOnlineEditor: CloudDocsEditorPowerpointOnline = {
  '.tag': 'powerpoint_online',
};
export const PaperWebEditor: CloudDocsEditorPaperWeb = {'.tag': 'paper_web'};
export const UserActionEditorType = {
  GOOGLE_DOCS: GoogleDocsEditor,
  GOOGLE_SHEETS: GoogleSheetsEditor,
  GOOGLE_SLIDES: GoogleSlidesEditor,
  WORD_ONLINE: WordOnlineEditor,
  EXCEL_ONLINE: ExcelOnlineEditor,
  POWERPOINT_ONLINE: PowerpointOnlineEditor,
  PAPER_WEB: PaperWebEditor,
};

export class UserActionPreOpenMessage {
  actionEvent: UserActionPreOpen;
  actionSource: ActionSource;
  userId: number;
  docPathOrId: string;
  data: {
    // We only have the open id if the Cloud Doc is a Canonical or a Cloud-Enhanced file
    webOpenId: string;
  };
}

export class UserActionCreateMessage {
  actionEvent: UserActionCreate;
  actionSource: ActionSource;
  userId: number;
  docPathOrId: string;
  data: {
    // We only have the open id if the Cloud Doc is a Canonical or a Cloud-Enhanced file
    webOpenId: string;
  };
}

export class UserActionPreviewMessage {
  actionEvent: UserActionPreview;
  actionSource: ActionSource;
  userId: number;
  docPathOrId: string;
  data: any;
}

export class UserActionLoadingSnackbarImpressionMessage {
  actionEvent: UserActionLoadingSnackBarImpression;
  // Needed for typecheck purposes in the logUserAction function
  actionSource?: ActionSource;
  userId: number;
  docPathOrId: string;
  data: {
    webOpenId: string;
  };
}

export class UserActionLoadingSnackbarOpenPreviewMessage {
  actionEvent: UserActionLoadingSnackBarOpenPreview;
  // Needed for typecheck purposes in the logUserAction function
  actionSource?: ActionSource;
  userId: number;
  docPathOrId: string;
  data: {
    webOpenId: string;
  };
}

export type UserActionMessage =
  | UserActionPreOpenMessage
  | UserActionCreateMessage
  | UserActionPreviewMessage
  | UserActionLoadingSnackbarImpressionMessage
  | UserActionLoadingSnackbarOpenPreviewMessage;

export const enum PromptActionTypes {
  Impression = 'impression',
  Yes = 'yes',
  No = 'no',
}

export interface PreferredEditorPrompt {
  body: ReactFormatResult;
  title: ReactFormatResult;
}

// keep sync with value in dropbox/edgestore/datatypes/cloud_doc_entity.py
export const enum CloudDocProviderIntegration {
  GDD_INTEGRATION = 1,
  PAPER_INTEGRATION = 2,
}

export const IntegrationViewOnly: IntegrationSetting = {'.tag': 'view_only'};
export const IntegrationCreateAndEdit: IntegrationSetting = {'.tag': 'create_and_edit'};
