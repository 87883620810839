// External imports
import {GoogleClientForm, GoogleEvent, GoogleMessageType} from 'external/google_dss_objects';

// structure of the Google actionUrl should always be kept in sync with
// dropbox/cloud_docs/google_dss/util.py
const ACTION_URL_REGEX = new RegExp(
  'https://docs.google.com/(document|spreadsheets|presentation)/fsip/edit.*fileUrl=.*'
);

interface MockGoogleEvent extends GoogleEvent {
  messageType: GoogleMessageType;
  sentTime: number;
  extra: {[key: string]: string | number};
}

export class MockGddIframeObject {
  private mockIframe: HTMLIFrameElement;
  private mockIframeUrl: string;
  private messageTypeToHandler: {[key: string]: (message?: MockGoogleEvent) => void} = {};

  constructor(mockIframe: HTMLIFrameElement, mockIframeUrl: string) {
    this.mockIframe = mockIframe;
    this.mockIframeUrl = mockIframeUrl;

    window.addEventListener('message', this.handleMessage, false);
  }

  cleanup() {
    window.removeEventListener('message', this.handleMessage);
  }

  registerCallback(messageType: GoogleMessageType, callback: (event?: GoogleEvent) => void) {
    this.messageTypeToHandler[messageType] = callback;
  }

  sendMessage(messageType: GoogleMessageType, data: {[key: string]: string}) {
    const mockGoogleEvent: MockGoogleEvent = {
      title: '',
      token: '',
      messageType: messageType,
      sentTime: Date.now(),
      extra: data,
    };
    if (this.mockIframe.contentWindow) {
      this.mockIframe.contentWindow.postMessage(
        JSON.stringify(mockGoogleEvent),
        this.mockIframeUrl
      );
    }
  }

  handleMessage = (event: MessageEvent) => {
    let messageType: GoogleMessageType;
    let message: MockGoogleEvent;
    try {
      message = JSON.parse(event.data);
      messageType = message.messageType;
    } catch (error) {
      throw new SyntaxError(`JSON.parse() failed for ${JSON.stringify(event.data)}`);
    }

    if (messageType in this.messageTypeToHandler) {
      this.messageTypeToHandler[messageType](message);
    }
  };
}

/**
 * MockGapi is a library that exposes the same interface as Google's gapi.
 * When in devbox, we use this interface and pass in the same arguments, expecting
 * to render the MockGddIframeObject. We use this library in unit testing.
 */
export class MockGapi {
  public loadedModules: string[] = [];
  private mockIframeUrl: string;
  public fsip: any = {};

  constructor(mockIframeUrl: string) {
    this.mockIframeUrl = mockIframeUrl;
    this.fsip['createIframe'] = this.createIframe.bind(this);
  }

  load(moduleName: string, cb: () => void): void {
    this.loadedModules.push(moduleName);
    cb();
  }

  createIframe(
    containerEl: Element,
    googleClientForm: GoogleClientForm,
    attributes: {}
  ): MockGddIframeObject {
    if (!ACTION_URL_REGEX.test(googleClientForm.actionUrl)) {
      throw new Error(`ActionUrl ${googleClientForm.actionUrl} is incorrect in mock iframe`);
    }
    const mockIframe = document.createElement('iframe');
    mockIframe.setAttribute('class', 'gdd-doc-iframe');
    // To replicate Google's editor iframe id
    mockIframe.setAttribute('id', 'docs_editor_frame');
    // We do this because the mockIframeUrl should exist; otherwise it's undefined
    // and we get runtime errors for which there are tests.
    mockIframe.src = this.mockIframeUrl!;

    containerEl.appendChild(mockIframe);
    return new MockGddIframeObject(mockIframe, this.mockIframeUrl!);
  }
}
