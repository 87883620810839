// Note when changing the file, you'll need to increase GDD_JS_BUNDLE_VERSION defined in
// dropbox/cloud_docs/google_dss/constants.py, so that we can upload it to that new folder
// in the S3 bucket.

// External imports
import React from 'react';
import {GoogleClientForm, GoogleEvent, GoogleIframeObject} from 'external/google_dss_objects';
import {values} from 'external/lodash';

// Internal imports
import {
  CloudDocActionsToDropbox,
  CloudDocActionsToIntermediateFrame,
  CloudDocActionsToProvider,
  GDDSpecificActionsToDropbox,
  IntermediateFrameInitMessage,
  MessagePayloadsToProvider,
  NavigateNewFragmentMessageToProvider,
} from 'modules/clean/cloud_docs/types';
import {
  GoogleMessageToHost,
  GoogleMessageToEditor,
  GApi,
} from 'modules/clean/cloud_docs/google_dss/google_dss_types';
// Types and interfaces
import {
  MockGapi,
  MockGddIframeObject,
} from 'modules/clean/cloud_docs/google_dss/gdd_intermediate_mock_iframe';
import {FrameMessengerClient} from 'modules/clean/frame_messenger/frame_messenger_client';

interface GddHostPageState {
  googleClientForm: GoogleClientForm;
  mockIframeUrl: string;
  docId: string;
}

export class GddHostPage extends React.Component<{}, GddHostPageState> {
  private frameMessenger: FrameMessengerClient;

  constructor(props: GddIframeContainerProps) {
    super(props);
    const handler = (msg: any) => {
      if (msg.origin.indexOf(process.env.WEBSERVER) === -1) {
        return false;
      }
      this.handleDropboxMessage(msg.data as IntermediateFrameInitMessage);
      window.removeEventListener('message', handler);
      return true;
    };

    window.addEventListener('message', handler);
    window.parent.postMessage(
      {
        action: 'intermediate-frame-preload',
      },
      '*'
    );

    // This is the fallback loading case fired on iframe.onload
    this.frameMessenger = new FrameMessengerClient([new RegExp(process.env.WEBSERVER)]);
    this.frameMessenger.configureParentMessaging(
      this.handleDropboxMessage,
      [CloudDocActionsToIntermediateFrame.Init],
      () => {
        this.frameMessenger.postMessageToParent(GDDSpecificActionsToDropbox.Ready);
        window.removeEventListener('message', handler);
      }
    );
    this.frameMessenger.startListening();
  }

  private handleDropboxMessage = (message: IntermediateFrameInitMessage) => {
    if (message.action === CloudDocActionsToIntermediateFrame.Init) {
      // Return immediately if we're already initialized from pagelet
      // otherwise set state to force re-render.
      if (this.state) {
        return;
      } else {
        this.setState({
          googleClientForm: {
            actionUrl: message.parameters.action_url,
            tpat: message.parameters.auth_token,
            tpatExpirationTime: message.parameters.auth_token_ttl,
            authEmail: message.parameters.user_email,
          },
          mockIframeUrl: message.parameters.mock_iframe_url,
          docId: message.parameters.doc_id,
        });
      }
    }
  };

  render() {
    if (!this.state) {
      return null;
    }
    return (
      <GddIframe
        docId={this.state.docId}
        googleClientForm={this.state.googleClientForm}
        mockIframeUrl={this.state.mockIframeUrl}
      />
    );
  }
}

export type RegisterClientCallback = (
  clientFrame: GoogleIframeObject | MockGddIframeObject
) => void;

export type IframeLoadCallback = (
  containerEl: HTMLDivElement,
  registerClient: RegisterClientCallback,
  logCallAttempt: () => void
) => void;

export interface GddIframeContainerProps {
  docId: string;
  onLoadIframe: IframeLoadCallback;
}

/**
 * A component that wraps the Google editor and passes messages between
 * Google's editor and our generic cloud_doc_iframe host component.
 */
export class GddIframeContainer extends React.Component<GddIframeContainerProps> {
  // TODO (T265334): Change from protected to private when reenabling bolt
  public clientFrame: GoogleIframeObject | MockGddIframeObject;
  private containerEl: HTMLDivElement;
  private frameMessenger: FrameMessengerClient;
  private frameCalledTs: number;
  private frameCalledLogged: boolean;
  private frameMessengerReady: boolean;

  constructor(props: GddIframeContainerProps) {
    super(props);

    this.frameMessenger = new FrameMessengerClient([new RegExp(process.env.WEBSERVER)]);
    this.frameMessenger.configureParentMessaging(
      this.handleDropboxMessage,
      values(CloudDocActionsToProvider)
    );
    this.frameMessenger.startListening();
  }

  private loadPostTTI = () => {
    this.clientFrame.registerCallback(
      GoogleMessageToHost.ClientFragmentsChanged,
      (event: GoogleEvent) => {
        this.frameMessenger.postMessageToParent(CloudDocActionsToDropbox.NavigateNewFragment, {
          cloudDocId: this.props.docId,
          docId: this.props.docId,
          token: event.token,
        });
      }
    );

    this.clientFrame.registerCallback(GoogleMessageToHost.AclFixerOpened, () => {
      this.frameMessenger.postMessageToParent(CloudDocActionsToDropbox.OpenAddAccessUI, {
        cloudDocId: this.props.docId,
        docId: this.props.docId,
      });
    });

    this.clientFrame.registerCallback(GoogleMessageToHost.SharingOpened, () => {
      this.frameMessenger.postMessageToParent(CloudDocActionsToDropbox.OpenSharingUI, {
        cloudDocId: this.props.docId,
        docId: this.props.docId,
      });
    });

    this.clientFrame.registerCallback(GoogleMessageToHost.TitleChanged, (event: GoogleEvent) => {
      this.frameMessenger.postMessageToParent(CloudDocActionsToDropbox.TitleChanged, {
        cloudDocId: this.props.docId,
        docId: this.props.docId,
        title: event.title,
      });
    });

    this.clientFrame.registerCallback(GoogleMessageToHost.ReloadHost, () => {
      this.frameMessenger.postMessageToParent(CloudDocActionsToDropbox.ReloadHost, {
        cloudDocId: this.props.docId,
        docId: this.props.docId,
      });
    });
  };

  private setContainerEl = (containerEl: HTMLDivElement) => {
    this.containerEl = containerEl;
  };

  private registerClient: RegisterClientCallback = clientFrame => {
    this.clientFrame = clientFrame;
    const docsIframe = document.querySelector('iframe') as HTMLIFrameElement;
    if (docsIframe) {
      docsIframe.focus();
      docsIframe.onload = () => {
        this.frameMessenger.postMessageToParent(GDDSpecificActionsToDropbox.FrameLoaded);
      };
    }

    clientFrame.registerCallback(GoogleMessageToHost.ClientReady, () => {
      this.frameMessenger.postMessageToParent(CloudDocActionsToDropbox.ChildReady);
    });
  };

  // Both this and handleDropboxMessage for parent-ready attempt to log the event
  // Because either could finish first, they both set variables that are checked.
  private logCallAttempt: () => void = () => {
    this.frameCalledTs = Date.now();
    this.sendCallAttemptToParent();
  };

  private sendCallAttemptToParent: () => void = () => {
    if (!this.frameCalledLogged && this.frameMessengerReady && this.frameCalledTs) {
      this.frameMessenger.postMessageToParent(CloudDocActionsToDropbox.ProviderFrameCalled, {
        frameCalledTs: this.frameCalledTs,
      });
      this.frameCalledLogged = true;
    }
  };

  public handleDropboxMessage = (message: MessagePayloadsToProvider) => {
    // Special case -- the frame messenger of the parent frame just registered
    // This is not an explicit message sent by the gdd_iframe_wrapper, it's sent by the
    // frame_messenger_host object implicitly.
    // This message can be sent more than once, we only care about the first time.
    if (message.action === CloudDocActionsToProvider.FrameMessengerParentReady) {
      this.frameMessengerReady = true;
      this.sendCallAttemptToParent();
    }

    if (message.action === CloudDocActionsToProvider.HostReady && this.clientFrame) {
      this.clientFrame.sendMessage(GoogleMessageToEditor.HostReady, {});
      this.loadPostTTI();
    } else if (message.action === CloudDocActionsToProvider.MetadataChanged) {
      this.clientFrame.sendMessage(GoogleMessageToEditor.MetadataChanged, {});
    } else if (message.action === CloudDocActionsToProvider.AddAccessUIResponse) {
      this.clientFrame.sendMessage(GoogleMessageToEditor.AclFixerClosed, {});
    } else if (message.action === CloudDocActionsToProvider.NavigateNewFragment) {
      const m = message as NavigateNewFragmentMessageToProvider;
      this.clientFrame.sendMessage(GoogleMessageToEditor.HostFragmentsChanged, {
        token: m.parameters.token,
      });
    }
  };

  public componentDidMount() {
    this.props.onLoadIframe(this.containerEl, this.registerClient, this.logCallAttempt);
  }

  public render() {
    return <div ref={this.setContainerEl} className="google-dss-iframe-container" />;
  }
}

export interface GddIframeProps {
  docId: string;
  googleClientForm: GoogleClientForm;
  // Used in devbox environments to show our mock page
  mockIframeUrl?: string;
}

export class GddIframe extends React.Component<GddIframeProps> {
  private loadGoogleIframe: IframeLoadCallback = (containerEl, registerClient, logCallAttempt) => {
    // If we aren't in prod, we use the mock gapi library defined below.
    // This allows us to test the calls and args we pass to Google's library.
    /* global: gapi is not a bug; it's imported as a script on the page from Google */
    // @ts-ignore
    const _gapi: GApi = process.env.IS_PROD ? gapi : new MockGapi(this.props.mockIframeUrl!);

    logCallAttempt();
    _gapi.load('fsip', () => {
      // Optional attributes. If given, then the attributes will be applied to the
      // iframe element.
      const attributes = {};

      const clientFrame = _gapi.fsip.createIframe(
        containerEl,
        this.props.googleClientForm,
        attributes
      );

      registerClient(clientFrame);
    });
  };

  public render() {
    return <GddIframeContainer docId={this.props.docId} onLoadIframe={this.loadGoogleIframe} />;
  }
}
