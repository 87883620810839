import {
  FrameMessengerBase,
  Message,
  MessageHandler,
  Parameters,
} from 'modules/clean/frame_messenger/frame_messenger_base';
import {logFrameMessage} from 'modules/clean/frame_messenger/frame_messenger_logger';

type OnParentReadyHandler = () => void;

export class FrameMessengerClient extends FrameMessengerBase {
  static readonly _REQUEST_PARENT_ORIGIN_POLL_DELAY = 100; // in msec

  private _trustedOriginForPosting: string | null = null;
  private _validActions: string[] = [];
  private _trustedMessageHandler: MessageHandler;

  private _parentMessageQueue: string[] = []; // Messages waiting to send once a verified parent origin is determined
  private _onParentReady: OnParentReadyHandler | null = null; // called when the parent origin is validated and set

  // trustedMessageFromParentHandler: method to call to handle trusted messages from parent
  // validActionsFromParent: array of action names from parent that should be processed.
  configureParentMessaging(
    trustedMessageHandler: MessageHandler,
    validActions: string[],
    onParentReady: OnParentReadyHandler | null = null
  ) {
    this._trustedOriginForPosting = null;
    this._validActions = validActions;
    this._trustedMessageHandler = trustedMessageHandler;
    this._onParentReady = onParentReady;
  }

  startListening() {
    super.startListening();

    this.requestParentOrigin();
  }

  postMessageToParent(action: string, parameterJson: Parameters = {}) {
    const message = this.packagePostMessage(action, parameterJson);
    if (this._trustedOriginForPosting === null) {
      this._parentMessageQueue.push(message);
    } else {
      this.getWindow().parent.postMessage(message, this._trustedOriginForPosting);
    }
  }

  protected dispatchMessage(event: MessageEvent, messageJson: Message) {
    logFrameMessage('frame_messenger_client', {event, messageJson});
    if (messageJson.action === 'parent-ready') {
      // This is a special message that is just for validating the parent origin.
      this.updateParentOrigin(event.origin);
    }

    // Check if we have a registered handler for this action.
    if (__in__(messageJson.action, this._validActions) && this._trustedMessageHandler !== null) {
      this._trustedMessageHandler(messageJson);
    }
  }

  // Resets origin domains that we've identified for sending postMessage to
  resetOriginsForPosting() {
    this._trustedOriginForPosting = null;
  }

  private updateParentOrigin(origin: string) {
    const previousParentOrigin = this._trustedOriginForPosting;
    if (!this.isOriginAllowed(origin)) {
      return false;
    }
    this._trustedOriginForPosting = origin;
    // Send any pending messages first
    if (this._parentMessageQueue.length > 0) {
      for (let i = 0; i < this._parentMessageQueue.length; i++) {
        const message = this._parentMessageQueue[i];
        const messageJson = JSON.parse(message);
        this.postMessageToParent(messageJson.action, messageJson.parameters);
      }
      this._parentMessageQueue = [];
    }
    if (
      this._trustedOriginForPosting != null &&
      previousParentOrigin == null &&
      this._onParentReady !== null
    ) {
      this._onParentReady();
    }
    return true;
  }

  private requestParentOrigin() {
    // This is the only postMessage that is allowed '*' origin. It does not contain any
    // sensitive information, rather, informs the parent to send a message so the child
    // can register the parent's origin.
    this.getWindow().parent.postMessage('{"action": "child-requesting-parent-origin"}', '*');
    const tryRequestParentOrigin = () => {
      if (this._trustedOriginForPosting === null) {
        this.requestParentOrigin();
      }
    };
    this.getWindow().setTimeout(
      tryRequestParentOrigin,
      FrameMessengerClient._REQUEST_PARENT_ORIGIN_POLL_DELAY
    );
  }
}

function __in__<T>(needle: T, haystack: T[]) {
  return haystack.indexOf(needle) >= 0;
}
