import {Message, Parameters} from 'modules/clean/frame_messenger/frame_messenger_base';
import * as lodash from 'external/lodash';

export type Data = {
  eventName?: string;
  messageJson?: Message;
  data?: Parameters;
  event?: MessageEvent;
};
type Event = {
  action: string;
  logger: string;
  firstRelevantFrame?: string;
  fullTrace?: string;
  data?: string;
  event?: MessageEvent;
};
const events: Event[] = [];

function hasQueryParam() {
  // URLSearchParams is not defined in IE11
  /* tslint:disable-next-line:no-typeof-undefined https://github.com/microsoft/tslint-microsoft-contrib/issues/415 */
  if (typeof URLSearchParams !== 'undefined') {
    const localWindow = window;
    if (!localWindow || !URLSearchParams) {
      return true;
    }

    /* global: URLSearchParams is built into the browser */
    const urlParams = new URLSearchParams(localWindow.location.search);
    return urlParams.get('frame_messenger') != null;
  } else {
    return false;
  }
}

export function logFrameMessage(logger: string, {eventName, messageJson, event, data}: Data) {
  // Don't blow up console in prod.
  if (process.env.IS_PROD || !hasQueryParam()) {
    return;
  }

  const action = eventName || (messageJson && messageJson.action) || '';
  const err = new Error();
  const {stack} = err;
  events.push({
    action,
    logger,
    firstRelevantFrame: getFirstRelevantFrame(logger, stack),
    fullTrace: stack,
    event,
    data,
  });
  logTable();
}

const IRRELEVANT_FRAMES: {[key: string]: boolean} = {
  file_viewer_interface_controller: true,
  frame_interface: true,
  window_interface: true,
};

/**
 * Gets the first item in the stack trace unrelated to the frame messenger.
 * This is a proxy for the callsite of postMessage.
 */
function getFirstRelevantFrame(logger: string, stack?: string) {
  if (!stack) {
    return stack;
  }

  // Magic to sanitize the stack trace and get the file names
  const frames = stack
    .split('at ')
    .map(row => {
      const match = row.match(/modules\/clean\/(.*)-vfl/);
      if (!match) {
        return match;
      }
      const path = match[1].split('/');
      return path[path.length - 1];
    })
    .filter(Boolean);

  // Loops over the filenames for each stack frame and
  // finds the first one unrelated to the frame messenger.
  for (const frame of frames) {
    if (!frame) {
      continue;
    }

    if (frame.startsWith('frame_messenger')) {
      continue;
    }

    if (IRRELEVANT_FRAMES[frame] != null) {
      continue;
    }

    return frame;
  }
  return '';
}

const logTable = lodash.debounce(() => {
  console.groupCollapsed('Frame Messenger Events');
  console.table(events);
  console.groupEnd();
}, 1000);
