// Handles messaging between frames (to/from child frames and parent frame)
// Note: FrameMessenger can support communication with a parent frame and child iframes (as is
//     the case for the progressive preview iframe). Therefore, child and parent members can both
//     be valid.

export type Parameters = any;

export interface Message {
  action: string;
  parameters?: Parameters;
}

export type MessageHandler = (msg: Message) => void;

export abstract class FrameMessengerBase {
  private _window: Window = window;
  private allowedOrigins: Array<RegExp | string>;

  constructor(allowedOrigins: Array<RegExp | string>) {
    this.allowedOrigins = allowedOrigins;
  }

  getWindow(): Window {
    return this._window;
  }

  setWindow(window: Window) {
    this._window = window;
  }

  startListening() {
    this.getWindow().addEventListener('message', this.handleUntrustedMessage);
  }

  stopListening() {
    this.getWindow().removeEventListener('message', this.handleUntrustedMessage);
  }

  protected regexIn(needle: string, haystack: Array<RegExp | string>) {
    if (haystack.indexOf(needle) > -1) {
      return true;
    }
    for (const item of haystack) {
      if (item instanceof RegExp && needle.match(item)) {
        return true;
      }
    }
    return false;
  }

  protected abstract dispatchMessage(event: MessageEvent, message: Message): void;

  /**
   * Strip standard port from origin. https://a.com:443 becomes https://a.com
   * @param origin
   */
  static stripStandardPort(origin: string) {
    if (origin.substr(0, 7) === 'http://') {
      return origin.replace(/:80/, '');
    }

    if (origin.substr(0, 8) === 'https://') {
      return origin.replace(/:443$/, '');
    }

    return origin;
  }

  isOriginAllowed(origin: string) {
    return this.regexIn(FrameMessengerBase.stripStandardPort(origin), this.allowedOrigins);
  }

  private handleUntrustedMessage = (event: MessageEvent) => {
    // Check if origin in parent + child whitelist
    if (!this.isOriginAllowed(event.origin)) {
      return;
    }
    let messageJson: Message;
    try {
      messageJson = JSON.parse(event.data);
    } catch (error) {
      return; // Bail if the message isn't proper JSON
    }
    if (!messageJson.action) {
      return;
    }

    this.dispatchMessage(event, messageJson);
  };

  protected packagePostMessage(action: string, parameters: Parameters) {
    const message: Message = {action, parameters};
    return JSON.stringify(message);
  }
}
